import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-final-form';
import cn from 'classnames';
import useIphoneDetection from '../../../../hooks/useIphoneDetection';
import { locationsQuery } from '../../../../queries';
import {
  setError,
  setLocationIds,
  setCurrentState,
  showPopupHeader,
  setResetPopupHandler,
  setChosenLocationNames,
  setLocationsResetButtonIsDisabled
} from '../../../../actions';
import NewSearchEmpty from '../../NewSearchCities/NewSearchEmpty';
import CheckboxButton from '../../../CheckboxButton';
import NewLocationTabs from '../NewSearchLocationTabs';
import Button from '../../../Button';
import { entitiesFilter } from '../../../../utils/helpers/common';
import NewSearchEmptyLocations from '../NewSearchEmptyLocations';

const NewSearchLocation = ({
  isFocused,
  modalParams,
  noLocations,
  searchString,
  cancelHandler,
  isInputOnFocus,
  closePopupHandler,
  toggleHandlerCities
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { change, getState, submit } = useForm();
  const isIphone = useIphoneDetection();
  const currentCity = useSelector(state => state.searchForm.currentCity);
  const chosenLocationNames = useSelector(
    state => state.searchForm.chosenLocationNames
  );
  const cityId = getState().values.city_id || '';
  const currentCityId = currentCity || cityId;
  const locationsQueryVariables = {
    cityId: currentCityId,
    scope: 'ALL',
    limit: 1000
  };
  const { data, error } = useQuery(locationsQuery(locationsQueryVariables), {
    variables: locationsQueryVariables
  });
  const locations = data?.locations || [];
  const currentLocationIds = getState().values.location_ids || [];
  const [chosenLocationIds, setChosenLocationIds] =
    useState(currentLocationIds);

  const onChangeCheckboxHandler = e => {
    const currentId = e.target.value;
    const updatedLocationIds = () => {
      return chosenLocationIds.includes(currentId)
        ? chosenLocationIds.filter(el => el !== currentId)
        : [...chosenLocationIds, currentId];
    };
    setChosenLocationIds(updatedLocationIds);
  };

  useEffect(() => {
    if (!chosenLocationNames.length) {
      setChosenLocationIds([]);
    }
  }, [chosenLocationNames]);

  const onCheckGroupHandler = (group, val) => {
    if (val) {
      const temp = {};
      chosenLocationIds.forEach(id => {
        temp[id] = true;
      });
      group.forEach(location => {
        temp[location.id] = true;
      });
      return setChosenLocationIds(Object.keys(temp));
    }
    const groupIds = group.map(location => location.id);
    setChosenLocationIds(prevState =>
      prevState.filter(id => !groupIds.includes(id))
    );
  };

  const closePopup = () => {
    dispatch(showPopupHeader());
    closePopupHandler();
    cancelHandler();
  };

  const onClickApplyBtnHandler = () => {
    change('location_ids', chosenLocationIds);
    dispatch(setLocationIds(chosenLocationIds));
    dispatch(
      setCurrentState({
        ...getState().values,
        location_ids: chosenLocationIds
      })
    );
    dispatch(
      setChosenLocationNames(
        locations
          .filter(location => chosenLocationIds.includes(location.id))
          .map(location => location.name.trim())
      )
    );
    if (modalParams?.initialScreen === 'locations') {
      return submit().then(closePopup);
    }
    closePopup();
  };

  const locationsList = entitiesFilter(locations, searchString).map(
    location => (
      <CheckboxButton
        data={location}
        key={location.id}
        cypress="location"
        withGroupType={true}
        name="search-locations"
        currentValues={chosenLocationIds}
        onChangeHandler={onChangeCheckboxHandler}
      />
    )
  );

  const emptySearch = !locationsList.length && searchString && (
    <NewSearchEmpty
      onClickHandler={cancelHandler}
      linkText={t('search.locations_search.back')}
    />
  );

  const locationTabs = !searchString && (
    <NewLocationTabs
      currentCityId={currentCityId}
      chosenLocationIds={chosenLocationIds}
      onCheckGroupHandler={onCheckGroupHandler}
      onChangeCheckboxHandler={onChangeCheckboxHandler}
    />
  );

  useEffect(() => {
    const locationsPopup = document.getElementsByClassName(
      'new-search-popup--locations'
    );
    const container = locationsPopup[0].querySelector(
      '.new-search-popup__container'
    );
    const touchHandler = () => {
      const input = locationsPopup[0].querySelector('input[type="search"]');

      input?.blur();
    };

    if (isIphone) {
      container?.addEventListener('touchstart', touchHandler);
    }

    return () => {
      if (isIphone) {
        container && container.removeEventListener('touchstart', touchHandler);
      }
    };
  }, [isIphone]);

  useEffect(() => {
    dispatch(setResetPopupHandler(() => setChosenLocationIds([])));

    return () => {
      dispatch(setResetPopupHandler(null));
      dispatch(setLocationsResetButtonIsDisabled(false));
    };
  }, []);

  useEffect(() => {
    dispatch(setLocationsResetButtonIsDisabled(chosenLocationIds.length === 0));
  }, [chosenLocationIds]);

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  return (
    <div
      className={cn([
        'new-search-popup__container',
        {
          'new-search-popup__container--locations':
            !(isInputOnFocus || !locationsList.length) && searchString
        }
      ])}
    >
      {isFocused && searchString && locationsList}
      {locationTabs}
      {emptySearch}
      {noLocations && (
        <NewSearchEmptyLocations
          onClickHandler={toggleHandlerCities}
          linkText={t('new_search.select_other_city')}
        />
      )}
      <Button
        stat="new-search-submit-locations"
        additionalClass="new-search-popup__apply-btn"
        text={t('search.buttons.add_to_search')}
        onClickHandler={onClickApplyBtnHandler}
        cypress="locations-apply-btn"
      />
    </div>
  );
};

NewSearchLocation.propTypes = {
  isFocused: PropTypes.bool,
  noLocations: PropTypes.bool,
  modalParams: PropTypes.object,
  cancelHandler: PropTypes.func,
  searchString: PropTypes.string,
  isInputOnFocus: PropTypes.bool,
  closePopupHandler: PropTypes.func,
  toggleHandlerCities: PropTypes.func
};

export default NewSearchLocation;
